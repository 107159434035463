import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import {partnersList} from "@/data/partners.js";

@Module
export default class PartnersModule extends VuexModule {

    partnersList = [];


  /* --- GETTERS --- */

  get getPartnersList(): any {
    return this.partnersList;
  }


  /* --- MUTATIONS --- */

  @Mutation
  [Mutations.SET_PARTNERS](partners: any) {
    this.partnersList = partners;
  } 

  @Action
  [Actions.GET_PARTNERS]() {
    this.context.commit(Mutations.SET_PARTNERS, partnersList);
  }
}