import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";


interface StoreInfo {

}

@Module
export default class CurrenciesModule extends VuexModule implements StoreInfo {
  contacts = null;

  contact = {};

  contactsPagination = {
    pagination: null,
    loading: true,
    error: null
  };

  contactsFilters = {
    search: '',
    page: 1,
    searchString : '',
    limit: parseInt(process.env.VUE_APP_PAGINATION || '20'),
  };

  internUsers = [];

  payments = [];


  /* --- GETTERS --- */

  get getInternUsers() {
    return this.internUsers;
  }

  get getContacts(): any {
    return this.contactsPagination;
  }

  get getContact(): any {
    return this.contact;
  }

  get getContactsPagination() {
    return this.contactsPagination;
  }

  get getContactsFilters(){
    return this.contactsFilters;
  }

  get getPageContacts(){
    return this.contactsFilters.page;
  }

  get getPayments() {
    return this.payments;
  }


  /* --- MUTATIONS --- */


  @Mutation
  [Mutations.SET_CONTACTS_PAGINATION](payload) {
    this.contactsPagination = payload;
  }

  @Mutation
  [Mutations.SET_PAGE_PAGINATION_CONTACTS](payload) {
    this.contactsFilters.page = payload;
  }

  @Mutation
  [Mutations.SET_CONTACT](payload) {
    this.contact = payload;
  }

  @Mutation
  [Mutations.SET_INTERN_USERS](payload) {
    console.log(payload);
    this.internUsers = payload;
  }

  @Mutation
  [Mutations.SET_PAYMENTS](payload) {
    this.payments = payload;
  }

  @Mutation
  [Mutations.CLEAR_FILTERS](payload) {
    this.contactsFilters = {
      search: '',
      page: 1,
      searchString : '',
      limit: parseInt(process.env.VUE_APP_PAGINATION || '20'),
    };
  }

  /* --- ACTIONS --- */

  @Action({rawError: true})
  [Actions.GET_CONTACTS](payload) {
    this.contactsPagination.loading = true;
    return ApiService.get(`leads?limit=10&page=${this.contactsFilters.page}&search=${this.contactsFilters.search}${this.contactsFilters.searchString}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CONTACTS_PAGINATION, { pagination: data, loading: false, error: null });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({rawError: true})
  [Actions.GET_CLIENTS](payload) {
    this.contactsPagination.loading = true;
    return ApiService.get(`clients?limit=10&page=${this.contactsFilters.page}&search=${this.contactsFilters.search}${this.contactsFilters.searchString}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CONTACTS_PAGINATION, { pagination: data, loading: false, error: null });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.SET_PAGE_PAGINATIONS_CONTACTS](payload) {
    this.context.commit(Mutations.SET_PAGE_PAGINATION_CONTACTS, payload.page);
    this.context.dispatch(payload.getAction);
  }

  @Action
  [Actions.GET_CONTACT_BY_ID](payload) {
    return ApiService.get(`contact`, payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CONTACT, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_INTERN_USERS](payload) {
    return ApiService.get(`internUsers`)
      .then(({ data }) => {
        console.log(data)
        this.context.commit(Mutations.SET_INTERN_USERS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({rawError: true})
  [Actions.UPDATE_STATUS](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`lead/status`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
      })
  }

  @Action({rawError: true})
  [Actions.UPDATE_STATUS_PARTNER](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`lead/partnerStatus`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
      })
  }

  @Action({rawError: true})
  [Actions.UPDATE_STATUS_LEGAL](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`lead/legalStatus`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
      })
  }

  @Action
  [Actions.UPDATE_OWNER](payload) {
    return ApiService.post(`lead/owner`, payload)
      .then(({ data }) => {
        this.context.dispatch(Actions.GET_CONTACTS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_OWNER_PARTNER](payload) {
    return ApiService.post(`lead/ownerPartner`, payload)
      .then(({ data }) => {
        this.context.dispatch(Actions.GET_CLIENTS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_PARTNER](payload) {
    return ApiService.post(`lead/partner`, payload)
      .then(({ data }) => {
        this.context.dispatch(Actions.GET_CLIENTS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.SEND_CUSTOM_SMS](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`sms/custom`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    })
  }

  @Action
  [Actions.SEND_DESIGNA_SMS](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`lead/designaSMS`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    })
  }

  @Action
  [Actions.UPDATE_CONTACT]() {
    return new Promise((resolve, reject) => {
      ApiService.post(`lead/update`, this.contact)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    }
    )
  }

  @Action
  [Actions.CREATE_ORDER](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`orders/create`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    }
    )
  }

  @Action
  [Actions.GET_PAYMENTS_BY_ID](payload) {
    return ApiService.get(`payments`, payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PAYMENTS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }


  @Action
  [Actions.CLEAR_FILTERS](payload) {
    this.context.commit(Mutations.CLEAR_FILTERS, payload);
  }

  @Action
  [Actions.CREATE_CONTACT](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`lead`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    }
    )
  }

  @Action
  [Actions.RESEND_PAYLINK](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`orders/resendLink`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    }
    )
  }
}