enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
  SET_AUTH = "setAuthAction",
  GET_CONTACTS = "getContactsAction",
  DELETE_NEWS_FEED = "deleteNewsFeedAction",
  SET_PAGE_PAGINATIONS_CONTACTS = "setPagePaginationsContactsAction",
  GET_DOCUMENTS = "getDocumentsAction",
  PREVIEW_DOCUMENT = "previewDocumentAction",
  DOWNLOAD_DOCUMENT = "downloadDocumentAction",
  DELETE_DOCUMENT = "deleteDocumentAction",
  UPLOAD_DOCUMENT = "uploadDocumentAction",
  GET_CONTACT_BY_ID = "getContactByIdAction",
  GET_INTERN_USERS = "getInternUsersAction",
  GET_FEED_ITEMS_BY_ID = "getFeedItemsByIdAction",
  CREATE_NEW_FEED_ITEM = "createNewFeedItemAction",
  UPDATE_STATUS = "updateStatusAction",
  UPDATE_OWNER = "updateOwnerAction",
  SEND_CUSTOM_SMS = "sendCustomSmsAction",
  SEND_DESIGNA_SMS = "sendDesignaSmsAction",
  UPDATE_CONTACT = "updateContactAction",
  GET_PARTNERS = "getPartnersAction",
  UPDATE_PARTNER = "updatePartnerAction",
  GET_CLIENTS = "getClientsAction",
  CREATE_ORDER = "sendPaylinkAction",
  SEND_SHORTLINK = "sendShortlinkAction",
  GET_PAYMENTS_BY_ID = "getPaymentsByIdAction",
  RENAME_DOCUMENT = "renameDocumentAction",
  GET_NOTIFICATIONS = "getNotificationsAction",
  RESEND_PAYLINK = "resendPaylinkAction",

  UPDATE_STATUS_PARTNER = "updatePartnerStatusAction",
  UPDATE_OWNER_PARTNER = "updatePartnerOwnerAction",

  UPDATE_STATUS_LEGAL = "updateLegalStatusAction",

  GET_BILLING_ITEMS = "getBillingItemsAction",
  SET_PAGE_PAGINATIONS_BILLING_ITEMS = "setPagePaginationsBillingItemsAction",
  DELETE_ORDER = "deleteOrderAction",
  SET_PARTNER_ABONO = "setPartnerAbonoAction",

  GET_BILLING_CLIENTS = "getBillingUsersAction",
  UPDATE_ORDER_STATUS = "setOrderStatusAction",

  CLEAR_FILTERS = "clearFiltersAction",

  CREATE_CONTACT = "createContactAction",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
  SET_CONTACTS = "setContactsMutation",
  SET_PAGE_PAGINATION_CONTACTS = "setPagePaginationContactsMutation",
  SET_CONTACTS_PAGINATION = "setContactsPaginationMutation",
  SET_DOCUMENTS = "setDocumentsMutation",
  SET_CONTACT = "setContactMutation",
  SET_INTERN_USERS = "setInternUsersMutation",
  SET_FEED_ITEMS = "setFeedItemsMutation",
  SET_PARTNERS = "setPartnersMutation",
  SET_PAYMENTS = "setPaymentsMutation",
  SET_NOTIFICATIONS = "setNotificationsMutation",
  SET_BILLING_ITEMS = "setBillingItemsMutation",
  SET_PAGE_PAGINATIONS_BILLING_ITEMS = "setPagePaginationsBillingItemsMutation",
  SET_BILLING_CLIENTS = "setBillingUsersMutation",
  CLEAR_FILTERS = "clearFiltersMutation",
}

export { Actions, Mutations };
