import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";
import ContactsModule from "@/store/modules/ContactsModule";
import DocumentationModule from "@/store/modules/DocumentationModule";
import FeedModule from "@/store/modules/FeedModule";
import PartnersModule from "@/store/modules/PartnersModule";
import NotificationsModule from "@/store/modules/NotificationsModule";
import BillingModule from "@/store/modules/BillingModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    ConfigModule,
    ThemeModeModule,
    ContactsModule,
    DocumentationModule,
    FeedModule,
    PartnersModule,
    NotificationsModule,
    BillingModule
  },
});

export default store;
