export const partnersList = [
    {
        id: 1,
        name: 'CTI',
        officialName: 'CTI',
        address: 'Pl tetuan 40',
        email:'',
        billingEmail:'',
        phone: '',
        nif : '',
        iban: '',
        zones: [],
        pvp: '',
        billing: false
    },
    {
    id: 2,
    name: 'Legal Borràs',
    officialName: 'Legal Borràs',
    address: '',
    email: '',
    billingEmail: '',
    phone: '',
    nif: '',
    iban: '',
    zones: ['zone1', 'zone2'],
    pvp: '100',
    billing: true
}]