import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";


@Module
export default class NotificationsModule extends VuexModule {

    notifications = [];

  /* --- GETTERS --- */

  get getNotifications(): any {
    return this.notifications;
  }

  @Mutation
  [Mutations.SET_NOTIFICATIONS](notifications: any): void {
    this.notifications = notifications;
  }

  @Action
  [Actions.GET_NOTIFICATIONS](): void {
    ApiService.get(`notifications`).then((response) => {
        this.context.commit(Mutations.SET_NOTIFICATIONS, response.data);
    });
  }

}