import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/contacts",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/contacts",
        name: "contacts",
        component: () => import("@/views/TableContacts.vue"),
        meta: {
          pageTitle: "Contacts",
          breadcrumbs: ["Contacts"],
        },
      },
      {
        path: "/contacts/:id/",
        name: "contact-profile",
        component: () => import("@/views/ContactProfile.vue"),
        meta: {
          pageTitle: "Contact Profile",
          breadcrumbs: ["Contact Profile"],
        },
      },
      {
        path: "/clients",
        name: "clients",
        component: () => import("@/views/TableClients.vue"),
        meta: {
          pageTitle: "Clients",
          breadcrumbs: ["Clients"],
        },
      },
      {
        path: "/clients/:id/",
        name: "client-profile",
        component: () => import("@/views/ClientProfile.vue"),
        meta: {
          pageTitle: "Client Profile",
          breadcrumbs: ["Client Profile"],
        },
      },
      {
        path: "/billing",
        name: "billing",
        component: () => import("@/views/TableBilling.vue"),
        meta: {
          pageTitle: "Billing",
          breadcrumbs: ["Billing"],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/google-callback",
        name: "google-callback",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/GoogleCallback.vue"),
        meta: {
          pageTitle: "Redirecting...",
        },
      },
      {
        path: "/microsoft-callback",
        name: "microsoft-callback",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/MicrosoftCallback.vue"),
        meta: {
          pageTitle: "Redirecting...",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // verify auth token before each page change
  store.dispatch(Actions.VERIFY_AUTH, { token: JwtService.getToken() });

  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    if (store.getters.isUserAuthenticated) {
      next();
    } else {
      next({ name: "sign-in" });
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
