import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";


@Module
export default class FeedModule extends VuexModule {

    feedItems = [];


  /* --- GETTERS --- */

  get getFeedItems(): any {
    return this.feedItems;
  }


  /* --- MUTATIONS --- */

  @Mutation
  [Mutations.SET_FEED_ITEMS](feedItems: any) {
    this.feedItems = feedItems;
  }

  @Action
  [Actions.GET_FEED_ITEMS_BY_ID](id): void {
    ApiService.get(`feed/${id}`).then((response) => {
      this.context.commit(Mutations.SET_FEED_ITEMS, response.data);
    });
  }

  @Action
  [Actions.CREATE_NEW_FEED_ITEM](payload): any {
    return new Promise((resolve, reject) => {
      ApiService.post(`feed`, payload).then((response) => {
        if(response?.data?.id){
          this.context.dispatch(Actions.GET_FEED_ITEMS_BY_ID, payload.user);
          resolve(response);
        }else{
          reject(response);
        }
      });
    })
  }
    
}