import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";


@Module
export default class BillingModule extends VuexModule {

    billingItems = null;

    billingItemsPagination = {
        pagination: null,
        loading: true,
        error: null
    };

    billingItemsFilters = {
        search: '',
        page: 1,
        searchString : '',
        limit: parseInt(process.env.VUE_APP_PAGINATION || '20'),
    };

    billingClients = [];

  /* --- GETTERS --- */

  get getBillingItems(): any {
    return this.billingItemsPagination;
  }

  get getBillingItemsFilters(){
    return this.billingItemsFilters;
}

get getBillingClients(){
    return this.billingClients;
}


  /* --- MUTATIONS --- */

  @Mutation
  [Mutations.SET_BILLING_ITEMS](billingItems: any) {
    this.billingItemsPagination = billingItems;
  }

  @Mutation
  [Mutations.SET_PAGE_PAGINATIONS_BILLING_ITEMS](payload) {
    this.billingItemsFilters.page = payload.page;
  }

  @Mutation
  [Mutations.SET_BILLING_CLIENTS](payload) {
    this.billingClients = payload;
  }

  @Action({rawError: true})
  [Actions.GET_BILLING_ITEMS](payload): any {
    return new Promise((resolve, reject) => {
      ApiService.get(`payments?limit=10&page=${this.billingItemsFilters.page}&search=${this.billingItemsFilters.search}${this.billingItemsFilters.searchString}`).then(({ data }) => {
        this.context.commit(Mutations.SET_BILLING_ITEMS, { pagination: data, loading: false, error: null });
        let ids = "";
        data.data.forEach(element => {
          ids += element.clientId + ",";
        });
        console.log(ids)
        this.context.dispatch(Actions.GET_BILLING_CLIENTS, ids);
        resolve(data.data);
      })
    });
  }

  @Action({rawError: true})
    [Actions.SET_PAGE_PAGINATIONS_BILLING_ITEMS](payload) {
      this.context.commit(Mutations.SET_PAGE_PAGINATIONS_BILLING_ITEMS, payload);
      this.context.dispatch(Actions.GET_BILLING_ITEMS);
    }

  @Action
  [Actions.GET_BILLING_CLIENTS](payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`payments/users?id=${payload}`).then(({ data }) => {
        this.context.commit(Mutations.SET_BILLING_CLIENTS, data);
        resolve(data.data);
      })
    });
  }

  @Action
  [Actions.UPDATE_ORDER_STATUS](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`orders/updateStatus`, payload).then(({ data }) => {
        resolve(data.data);
      }).catch((error) => {
        reject(error);
      })
    });
  }

  @Action
  [Actions.DELETE_ORDER](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`orders/delete`, payload).then(({ data }) => {
        resolve(data.data);
      }).catch((error) => {
        reject(error);
      }
      )
    });
  }

  @Action
  [Actions.SET_PARTNER_ABONO](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`orders/updateAbonadoPartner`, payload).then(({ data }) => {
        resolve(data.data);
      }).catch((error) => {
        reject(error);
      }
      )
    });
  }
  
}