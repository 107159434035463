import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import axios from "axios";
import Swal from "sweetalert2";

interface StoreInfo {

}

@Module
export default class DocumentationModule extends VuexModule implements StoreInfo {

  documents = {}


  /* --- GETTERS --- */

  get getDocuments(): any {
    return this.documents;
  }


  /* --- MUTATIONS --- */


  @Mutation
  [Mutations.SET_DOCUMENTS](payload) {
    this.documents = payload;
  }


  /* --- ACTIONS --- */

  @Action
  [Actions.GET_DOCUMENTS](payload) {
    return ApiService.get(`documents/get-by-client`, payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DOCUMENTS, data);
      })
      .catch(({ response }) => {
        alert("error retrieving documents")
      });
  }

  @Action
  [Actions.DOWNLOAD_DOCUMENT](payload) {
    ApiService.get(`documents/download/${payload.id}/${payload._id}/${payload.documentName}/${payload.download}`)
      .then(({ data }) => {
        if(payload.download){
          window.open(
            data, "_blank");
        }else{
          window.open(
            data, "_blank");
        }
      })
      .catch(({ response }) => {
        alert("error downloading document")
      }
      );
  }

  @Action
  [Actions.PREVIEW_DOCUMENT](payload) {
    ApiService.get(`documents/preview/${payload.id}/${payload._id}/${payload.documentName}`)
      .then(({ data }) => {
        console.log(data)
      })
      .catch((err) => {
        console.log(err)
      }
      );
  }

  @Action
  [Actions.UPLOAD_DOCUMENT](payload) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append('file', payload.file);
      formData.append('id', payload.id);
      formData.append('origin', "internal");
      axios.post(process.env.VUE_APP_API_URL + '/documents/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then((response) => {
        this.context.dispatch(Actions.GET_DOCUMENTS, payload.id);
        resolve(response);
      })
        .catch(function () {
          reject();
        });
    }
    )
  }

  @Action
  [Actions.SEND_SHORTLINK](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/shortlink`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    })
  }

  @Action
  [Actions.RENAME_DOCUMENT](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/documents/rename`, payload)
        .then(({ data }) => {
          if (data?.code == 1) {
            Swal.fire({
              title: 'Un documento con ese nombre ya existe',
              text: "¿Quieres sobreescribirlo?",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Sobreescribir'
            }).then((result) => {
              if (result.isConfirmed) {
                Swal.fire(
                  'Guardado!',
                  'Se ha cambiado el nombre con éxito',
                  'success'
                )
                this.context.dispatch(Actions.RENAME_DOCUMENT, { ...payload, force: true })
              }
            });
          }
          this.context.dispatch(Actions.GET_DOCUMENTS, payload.id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    })
  }

  @Action
  [Actions.DELETE_DOCUMENT](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/documents/delete`, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_DOCUMENTS, payload.id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    })
  }
}